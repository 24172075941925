import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import './payment.scss';
import { Nav, Footer } from "../../components";

export const Payment = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <>
            <Helmet>
                <title>Оплата і доставка | Взуттєва Мрія</title>
                <meta name="description" content="Деталі про оплату і доставку на нашому сайті. Дізнайтесь більше про наші умови доставки і способи оплати." />
                <meta name="keywords" content="оплата, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Оплата і доставка | Взуттєва Мрія" />
                <meta property="og:description" content="Деталі про оплату і доставку на нашому сайті. Дізнайтесь більше про наші умови доставки і способи оплати." />
            </Helmet>
            <Nav />
            <main className="exchange">
                <div className="container">
                    <div className="exchange__wrapper">
                        <h5 className="exchange__subtitle">Оплата і доставка</h5>
                        <div className="exchange__content">
                            <h6 className="exchange__subtitle">Доставка</h6>
                            <p className="exchange__text">
                                Доставка здійснюється Новою Поштою, та УкрПоштою в день оплати замовлення.
                            </p>
                            <h6 className="exchange__subtitle">Оплата</h6>
                            <p className="exchange__text">
                                Оплата здійснюється шляхом перерахування платежу на розрахунковий рахунок Приватбанку.
                            </p>
                            <h6 className="exchange__subtitle">Варіанти оплати</h6>
                            <ul className="exchange__list">
                                <li className="exchange__item">100% оплата (в такому разі Ви не сплачуєте зайві кошти на комісію перевізника за накладний платіж)</li>
                                <li className="exchange__item">По передоплаті 200 грн - решту коштів сплачуєте на пошті, безпосередньо після огляду та примірки взуття.</li>
                            </ul>
                            <p className="exchange__text">
                                При оплаті замовлення до 15:00 відправлення здійснюється в день оплати, якщо оплата замовлення після 15:00, то залежно від завантаженості товар може бути надіслано наступного дня.
                            </p>
                        </div>
                        <h5 className="exchange__subtitle">Знижки та Акції</h5>
                        <div className="exchange__content">
                            <p className="exchange__text">
                                Безкоштовна доставка при замовленні від 2-х пар.
                            </p>
                            <p className="exchange__text">
                                Для постійних клієнтів пропонуємо знижки!
                            </p>
                        </div>
                        <h5 className="exchange__subtitle">Як замовити взуття</h5>
                        <div className="exchange__content">
                            <ol className="exchange__list">
                                <li className="exchange__item">Ви оформляєте замовлення.</li>
                                <li className="exchange__item">Наш менеджер зв'язується з вами, після уточнення всіх деталей вашого замовлення ми відправляємо його в будь-яку точку України з частковою оплатою післяплатою або за повною передоплатою на ваш вибір.</li>
                                <li className="exchange__item">Ми не надсилаємо замовлення без часткової або повної передоплати.</li>
                                <li className="exchange__item">У телефонному режимі – ви можете отримати консультацію, відредагувати заявку.</li>
                                <li className="exchange__item">У разі відсутності товару на складі термін доставки може бути продовжений за вашою згодою.</li>
                                <li className="exchange__item">Надсилаємо в день замовлення. Замовлення, сформовані до 15:00 ми надсилаємо в день оплати замовлення.</li>
                            </ol>
                        </div>
                        <h5 className="exchange__subtitle">Як зняти мірки з ноги?</h5>
                        <div className="exchange__content">
                            <p className="exchange__text">
                                <img src="https://olla.ua/image/data/banner/merki.png" alt="Як зняти мірки з ноги" />
                            </p>
                            <p className="exchange__text">
                                Для того щоб визначити розмір взуття, потрібно виміряти довжину стопи.
                            </p>
                            <p className="exchange__text">
                                Для цього необхідно стати обома ногами на аркуш паперу і, тримаючи ручку вертикально, окреслити контури обох ніг.
                            </p>
                            <p className="exchange__text">
                                Потім потрібно виміряти відстані між найбільш віддаленими точками для обох ніг в сантиметрах.
                            </p>
                            <p className="exchange__text">
                                Якщо отримані відстані на ногах відрізняються, то варто орієнтуватися на велику довжину.
                            </p>
                            <p className="exchange__text">
                                Заміри на сайті наведені для внутрішньої довжини взуття (довжина по устілці).
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import './search.scss';
import { Product } from "../Product";
import { fetchAllPosts } from '../../redux/slices/post';

export const Search = () => {
    const dispatch = useDispatch();
    const searchRef = useRef(null);
    
    useEffect(() => {
        dispatch(fetchAllPosts());
    }, [dispatch]);

    const { posts } = useSelector(state => state.post);
    
    // Initialize searchProduct with all posts
    const [searchProduct, setSearchProduct] = useState([]);

    useEffect(() => {
        if (posts && posts.data) {
            setSearchProduct(posts.data); // Set all products initially
        }
    }, [posts]);

    const onChangeSearch = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        if (searchTerm.length > 0) {
            const searchResult = posts.data.filter(post => 
                post.title.toLowerCase().includes(searchTerm) || 
                post.article.toLowerCase().includes(searchTerm)
            );
            setSearchProduct(searchResult);
        } else {
            // Show all products if search input is cleared
            setSearchProduct(posts.data);
        }
    };

    return (
        <div className="account__edit-product">
            <div className="account__search">
                <button onClick={() => searchRef.current.focus()} className="account__search-btn"></button>
                <input 
                    type="text" 
                    ref={searchRef} 
                    onChange={onChangeSearch} 
                    className="account__search-input" 
                    placeholder='Пошук'
                />
            </div>

            {searchProduct.length === 0 ? (
                <p className="account__not-search">Знайдіть необхідний товар</p>
            ) : (
                <div className="account__search-product">
                    {searchProduct.map((post) => (
                        <Product key={post._id} edit={true} data={post}/>
                    ))}
                </div>
            )}
        </div>  
    );
};

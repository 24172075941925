import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import './about-us.scss';
import { Nav, Footer } from "../../components";

export const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
             <Helmet>
                <title>Про нас | Взуттєва Мрія</title>
                <meta name="description" content="ShoeDream - ваш інтернет-магазин жіночого взуття. Індивідуальний підхід, широкий вибір моделей і вигідні ціни. Дізнайтесь більше про нас і наші послуги!" />
                <meta name="keywords" content="гарантія, доставка, взуття, інтернет-магазин, взуттєва мрія, магазин взуття, обувь, магазин обуви, кросівки, сліпони, мокасини, туфлі, кросовки, туфли, мокасины, балетки, розпродаж, акции, акції" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="Про нас | Взуттєва Мрія" />
            </Helmet>
            <Nav />
            <main className="about-us">
                <div className="container">
                    <div className="about-us__wrapper">
                        {/* Замінений текст */}
                        <div className="about-us-page" style={{ fontFamily: 'Arial, sans-serif', maxWidth: '800px', margin: '0 auto', padding: '20px' }}>
                            <h1 className="title" style={{ textAlign: 'center', color: '#333', borderBottom: '2px solid #333', paddingBottom: '10px', marginBottom: '20px' }}>
                                КЛІЄНТИ РОБЛЯТЬ ЗАМОВЛЕННЯ В ІНТЕРНЕТ-МАГАЗИНІ ShoeDream!
                            </h1>
                            <p className="paragraph" style={{ color: '#333', lineHeight: '1.6', marginBottom: '20px' }}>
                                В Інтернеті можна зустріти чимало магазинів, що пропонують великий вибір жіночого взуття. Але саме до нас хочеться повертатися знову і знову.
                            </p>

                            <h2 className="subtitle" style={{ color: '#666', fontSize: '1.5rem', marginBottom: '10px' }}>
                                МИ ПРОПОНУЄМО ВАМ ОСОБЛИВІ УМОВИ:
                            </h2>
                            <ul className="list" style={{ color: '#333', lineHeight: '1.6', marginBottom: '20px', paddingLeft: '20px' }}>
                                <li>Індивідуальний підхід фахівця. Коли надходить нова колекція взуття весна-літо або ж колекція взуття осінь-зима - покупцям буває важко зорієнтуватися у всьому різноманітті новинок. Наш менеджер по телефону допоможе вам визначитися з товарами, які підходять саме вам.</li>
                                <li>Ми періодично проводимо розпродажі жіночих моделей. Ви будете приємно здивовані цінами на взуття в магазині ShoeDream.</li>
                                <li>У нас завжди є з чого вибрати. Неважливо, яку категорію ви просуваєте: осінь-зима, або ж весна-літо - ви завжди зможете знайти варіанти, які підходять вам за зовнішнім виглядом і ціною.</li>
                                <li>Ми несемо відповідальність за всі товари.</li>
                            </ul>

                            <p className="paragraph" style={{ color: '#333', lineHeight: '1.6', marginBottom: '20px' }}>
                                Якщо ви шукаєте місце, де представлені взуттєві новинки, то ви зайшли за правильною адресою.
                            </p>

                            <p className="paragraph" style={{ color: '#333', lineHeight: '1.6', marginBottom: '20px' }}>
                                Наш магазин пропонує різноманіття взуття для жінок: балетки, босоніжки та сандалі, ботильйони, черевики, кросівки, ботфорти, туфлі, чоботи, напівчоботи, мокасини і сліпони. Тут ви знайдете ідеальну пару для будь-якого випадку та настрою: від елегантних туфель для офісу до зручних кросівок для активного відпочинку. Зробивши замовлення в нашому інтернет-магазині, ви зможете бути модним і стильним як восени-взимку, так і навесні-влітку. Зробити замовлення в нашому магазині легко — просто кількома кліками на сайті або за номером телефону.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
